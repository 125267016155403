<template>
	<div class="warehouseReceipt">
		<div class="warehouseReceipt-title">入库开单</div>
		<div class="warehouseReceipt-form">
			<el-form ref="form" :model="form" label-width="100px">
				<el-row :gutter="24">
					<el-col :span="6">
						<el-form-item label="入库日期" required>
							<el-date-picker v-model="form.date" type="date" placeholder="选择入库日期"
								value-format="yyyy-MM-dd" style="width: 100%;">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="仓库" required>
							<el-select v-model="form.warehouse_id" filterable placeholder="请选择仓库" style="width: 100%;">
								<el-option v-for="item in warehouseList" :key="item.id" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="供应商" required>
							<el-select v-model="form.erp_supplier_id" filterable placeholder="请选择供应商"
								style="width: 100%;">
								<el-option v-for="item in supplierList" :key="item.id" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="经办人" required>
							<el-input v-model="form.handler" placeholder="请输入经办人"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="备注">
							<el-input v-model="form.remark" placeholder="请输入备注"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="实付" required>
							<el-input v-model="form.amount" placeholder="请输入实付金额"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="warning" icon="el-icon-plus" @click="addFn()">新增</el-button>
			</div>
			<el-table :data="tableData" border :header-cell-style="{
                background: '#f5f7fa',
                fontWeight: 'bold',
                color: '#303133'
            }">
				<el-table-column label="商品名称" align="center" width="180">
					<template slot-scope="scope">
						<span>{{ scope.row.goods_name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="商品主图" align="center">
					<template slot-scope="scope">
						<el-image style="width: 50px; height: 50px" :src="scope.row.goods_thumb"
							:preview-src-list="[scope.row.goods_pic]">
						</el-image>
					</template>
				</el-table-column>
				<el-table-column label="商品简介" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.goods_intro }}</span>
					</template>
				</el-table-column>
				<el-table-column label="自定义属性" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.attribute }}</span>
					</template>
				</el-table-column>
				<el-table-column label="采购价" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.price }}</span>
					</template>
				</el-table-column>
				<el-table-column label="数量" align="center" width="180">
					<template slot-scope="scope">
						<span>{{ scope.row.count }}</span>
					</template>
				</el-table-column>
				<el-table-column label="小计" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.amount }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button type="warning" size="mini"
							@click="dialogVisible = true, dialogType = 2, openDialog(scope.row, scope.$index)">编辑</el-button>
						<el-button type="danger" size="mini" @click="removeFn(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 新增编辑 -->
		<el-dialog :title="dialogType == 1 ? '新增' : '编辑'" :visible.sync="dialogVisible" :close-on-click-modal="false"
			v-el-drag-dialog width="30%">
			<el-form ref="form" :model="dialogForm" label-width="100px">
				<el-form-item label="商品名称">
					<el-select v-model="dialogForm.goods_id" filterable remote placeholder="请输入商品名称"
						:remote-method="remoteMethod" @change="changeGoods" style="width: 100%;">
						<el-option v-for="item in goodsList" :key="item.id" :label="item.goods_name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="商品主图：">
					<el-image v-if="dialogForm.goods_pic" style="width: 50px; height: 50px" :src="dialogForm.goods_pic"
						:preview-src-list="[dialogForm.goods_pic]">
					</el-image>
				</el-form-item>
				<el-form-item label="商品简介：">
					<span>{{ dialogForm.goods_intro }}</span>
				</el-form-item>
				<el-form-item label="自定义属性：">
					<span>{{ dialogForm.attribute }}</span>
				</el-form-item>
				<el-form-item label="采购价：">
					<el-input v-model="dialogForm.price" placeholder="请输入商品采购价"
						@blur="blurInputCount(dialogForm, 1)"></el-input>
				</el-form-item>
				<el-form-item label="数量：">
					<el-input v-model="dialogForm.count" placeholder="请输入商品数量"
						@blur="blurInputCount(dialogForm, 2)"></el-input>
				</el-form-item>
				<el-form-item label="小计：">
					<el-input v-model="dialogForm.amount" placeholder="请输入商品小计"
						@blur="blurInputCount(dialogForm, 3)"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click="dialogConfirmFn">确 定</el-button>
			</span>
		</el-dialog>
		<div class="warehouseReceipt-footer">
			<el-button type="success" @click="confirmFn(1)">保存并查看</el-button>
			<el-button type="success" @click="confirmFn(2)">保存并继续</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				// 新增、编辑弹窗
				dialogType: 1, //1新增2编辑
				dialogVisible: false,
				dialogForm: {
					goods_id: ''
				},
				form: {
					date: '',
					warehouse_id: '',
					erp_supplier_id: '',
					amount: '',
					remark: '',
				},
				tableData: [],
				tableIndex: '',
				warehouseList: [], //仓库
				supplierList: [], //供应商
				goodsList: [],
				inputGoodsList: []
			}
		},
		computed: {
			total_amount() {
				if (this.form.total_amount !== '') {
					return this.form.total_amount.toFixed(2);
				}
			},
			coupon_amount() {
				if (this.form.coupon_amount !== '') {
					return this.form.coupon_amount.toFixed(2);
				}
			}
		},
		mounted() {
			this.getWarehouse()
			this.getSupplierList()
		},
		methods: {
			// 仓库
			getWarehouse() {
				this.$http.post(`erp/v1/warehouse/lst`, {
					pageNum: 100
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.warehouseList = data.rows
						if (this.warehouseList) {
							this.form.warehouse_id = this.warehouseList[0].id
						}
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 供应商
			getSupplierList() {
				this.$http.post(`/erp/v1/supplier/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.supplierList = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 商品名称
			remoteMethod(e) {
				this.$http.post(`/erp/v1/goods/lst`, {
					pageNum: 100,
					key: e,
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.goodsList = data.rows
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 获取商品
			changeGoods(e) {
				this.goodsList.forEach(item => {
					if (item.id == e) {
						this.dialogForm = item
						this.dialogForm.goods_id = item.id
					}
				});
			},
			// 新增编辑商品确定
			dialogConfirmFn() {
				if (!this.dialogForm.goods_id) {
					this.$message.error('请选择商品');
					return
				}
				if (!this.dialogForm.count) {
					this.$message.error('请输入商品数量');
					return
				}
				if (this.dialogType == 1) {
					this.tableData.push(this.dialogForm)
				} else {
					this.$set(this.tableData, this.tableIndex, this.dialogForm)
				}
				this.dialogVisible = false
				setTimeout(() => {
					this.calculatePrice()
				}, 500);
			},
			// 新增
			addFn() {
				if (!this.form.date) {
					this.$message.error('请选择入库日期');
					return
				}
				if (!this.form.warehouse_id) {
					this.$message.error('请选择仓库');
					return
				}
				if (!this.form.erp_supplier_id) {
					this.$message.error('请选择供应商');
					return
				}
				if (!this.form.handler) {
					this.$message.error('请输入经办人');
					return
				}
				this.dialogType = 1
				this.dialogVisible = true
				this.dialogForm = {}
			},
			// 编辑弹窗
			openDialog(item, index) {
				this.dialogForm = item
				this.dialogForm.goods_id = item.id
				this.tableIndex = index
			},
			// 删除
			removeFn(item) {
				let index = this.tableData.indexOf(item)
				this.tableData.splice(index, 1)
				this.calculatePrice()
			},
			// 计算价格
			calculatePrice() {
				this.inputGoodsList = []
				this.form.amount = 0
				this.tableData.forEach(item => {
					// if (item.count > item.stock) {
					//     item.count = item.stock
					// }
					this.$set(item, 'amount', Number(item.price) * item.count)
					// 实收
					this.form.amount += Number(item.price) * Number(item.count)
					// this.form.amount = this.form.amount.toFixed(2)
					this.inputGoodsList.push({
						goods_id: item.goods_id,
						count: item.count,
						price: item.price,
					})
				})
			},
			// 操作商品数量
			blurInputCount(item, type) {
				// if (this.dialogForm.count > this.dialogForm.stock) {
				//     this.dialogForm.count = this.dialogForm.stock
				// }
				if (type == 3) {
					this.$set(this.dialogForm, 'price', Number(item.amount) / item.count)
					this.dialogForm.price = this.dialogForm.price.toFixed(2)
				} else {
					this.$set(this.dialogForm, 'amount', Number(item.price) * item.count)
				}
			},
			// 开单
			confirmFn(type) {
				if (this.inputGoodsList.length <= 0) {
					this.$message.error('请选择商品');
					return
				}
				this.$http.post(`erp/v1/rkd/add`, {
					...this.form,
					goods_list: JSON.stringify(this.inputGoodsList),
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						if (type == 1) {
							this.$router.push("/warehouseManagement");
							// 存储路由
							sessionStorage.setItem('activeMenu', 'warehouseManagement');
							setTimeout(() => {
								window.location.reload();
							}, 1000)
						} else {
							this.$message.success('操作成功');
							setTimeout(() => {
								window.location.reload();
							}, 1000)
						}
					} else {
						this.$message.error(msg);
					}
				});
			}
		},
	}
</script>
<style lang="scss" scoped>
	.warehouseReceipt {
		.warehouseReceipt-title {
			text-align: center;
			font-size: 30px;
			margin-bottom: 20px;
		}

		.warehouseReceipt-form {
			background: #fff;
			padding: 30px 20px 10px 20px;
			border-radius: 10px;
			margin-bottom: 20px;
		}

		.warehouseReceipt-footer {
			display: flex;
			justify-content: center;
			margin-top: 30px;
		}
	}
</style>